<template>
  <div>
    <div id="map" style="width: 100%; height: 100vh;"></div>
    <div class="btn">
      <button @click="downFile" :disabled="downData.length==0">导出SHP</button>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { open } from "shapefile";
var shpwrite = require("shp-write");
export default {
  data() {
    return {
      map:"",
      index:1, //选中的标识
      onCtrl:false,
      allCheckData: {}, //选中的区块，用于绘制标记
      downData:[],//所有要下载的数据
    };
  },
  mounted() {
    let that = this;
    this.watchKeyEvent();
    let map = L.map("map", {
      center: [24.485666, 118.095498], // 中心位置
      zoom: 12, // 缩放等级
      attributionControl: true, // 版权控件
      zoomControl: true, //缩放控件
    });
    this.map = map;
    L.tileLayer(
      "http://wprd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"
    ).addTo(map); // 加载底图
    this.getShpData().then((res) => {

      res.forEach((item) => {

        let properties = item.properties;
        let customPopup = that.getHtml(properties)
        
        // specify popup options
        let customOptions = {
          maxWidth: "1100",
          className: "tableClass",
        };
        let allGeoData = [];
        if( item.geometry.type === "MultiPolygon"){
          item.geometry.coordinates.forEach((data) => {
          let allData = JSON.parse(JSON.stringify(item));
          allData.geometry.type = "Polygon";
          allData.geometry.coordinates = data;
          let geoData = L.geoJSON(allData, {}).addTo(map);
          allGeoData.push(geoData);
        });
        }else {
          let geoData = L.geoJSON(item, {}).addTo(map);
          allGeoData.push(geoData);
        }
        allGeoData.forEach((data) => {
          map.fitBounds(data.getBounds());
           data.bindPopup(customPopup,customOptions);
          data.on("click", function (e) {
            data.pm.enable({
              limitMarkersToCount: 20,
              snapDistance: 50,
              limitMarkerToClick: true,
            });
            
            if (that.onCtrl){
              let bounds = data.getBounds();
              let lng = (bounds._northEast.lng + bounds._southWest.lng ) /2
              let lat = (bounds._northEast.lat + bounds._southWest.lat ) /2
              if (!that.allCheckData[data._leaflet_id]){
                that.drawIcon(that.index++,[lat,lng]);
                that.allCheckData[data._leaflet_id] = that.index;
                that.downData.push(e.layer.feature)
              }
            }else {
             
            }
            //  that.downFile(e.layer.feature)
          });
        });
      });
    });
  },
  methods: {
    getShpData() {
      return new Promise((resolve, reject) => {
        let allShpData = [];
        open("./files/BEN_adm/BEN_adm0.shp")
        // open("./files/myshapes/mypolygons.shp")
          .then((source) =>
            source.read().then(function log(result) {
              if (result.done) {
                resolve(allShpData);
                return result;
              }
              console.log(result.value, 334);
              allShpData.push(result.value);

              return source.read().then(log);
            })
          )
          .catch((error) => console.error(error.stack));
      });
    },
    //下载shp文件
    downFile() {
      var options = {
        folder: "myshapes",
        types: {
          point: "mypoints",
          polygon: "mypolygons",
          line: "mylines",
        },
      };
      // a GeoJSON bridge for features
      console.log(this.downData);
      shpwrite.download(
        {
          type: "FeatureCollection",
          features: this.downData,
        },
        options
      );
    },
    watchKeyEvent() {
      const setKeyStatus = (keyCode, status) => {
        switch (keyCode) {
          case 16:
            if (this.onShfit === status) return;
            console.log("shif", status ? "按下" : "抬起");
            this.onShfit = status;
            break;
          case 17:
            if (this.onCtrl === status) return;
            console.log("ctrl", status ? "按下" : "抬起");
            this.onCtrl = status;
            break;
        }
      };
      document.onkeydown = (e) => {
        setKeyStatus(e.keyCode, true);
      };
      document.onkeyup = (e) => {
        setKeyStatus(e.keyCode, false);
      };
    },
    // 绘制文本标识
    drawIcon(index, latlng) {
      let icon = L.icon({
        iconUrl: "./test.jpg", //marker图片地址
        iconSize: [30, 30], //marker宽高
        iconAnchor: [0, 0], //marker中心点位置
      });
      let marker = L.marker(latlng, { icon: icon }).addTo(this.map); //dt是点的属性信息 对象格式
      let markerIcon = L.divIcon({
        html: "<div>"+index+"</div>", //marker标注
        className: "my-div-icon",
        iconSize: [30, 30], //marker宽高
        iconAnchor: [2, 0], //文字标注相对位置
      });
      L.marker(latlng, { icon: markerIcon }).addTo(this.map);
    },
    //弹窗的html
    getHtml(properties){
      let customPopup = "";
        let th = "";
        let td = "";
        let thArr = [];
        let tdArr = [];
        let index = 0;
        for (let key in properties) {
          th = th + "<th style='text-align: center;'>" + key + "</th>";
          td =
            td +
            "<td style='text-align: center;'> <input style='text-align: center;' value=" +
            properties[key] +
            "></input>  ";
          ("</td>");
          index++;
          if (index === 6) {
            thArr.push(th);
            tdArr.push(td);
            th = "";
            td = "";
            index = 0;
          }
        }
        let tableHtml = "";
        thArr.forEach((item, index) => {
          tableHtml =
            tableHtml + "<tr>" + item + "</tr><tr>" + tdArr[index] + "</tr>";
        });

        tableHtml = tableHtml + "<tr>" + th + "</tr><tr>" + td + "</tr>";
        customPopup =
          "<table border='1' cellspacing='0' cellpadding='0'><tbody>" +
          tableHtml +
          "</tbody></table>";
          return customPopup
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn{
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: 99999;
}
</style>
